import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import diaryImage from "../images/diary.jpg"
import HeadElements from "../components/head_attributes"

const Home = ({ data }) => {
  const {
    swapi:{blogpages, homepage:{seoTitle, url,searchDescription, ...homepage} },
  } = data

  const formatDate = d => {
    let date = new Date(d)
    return Intl.DateTimeFormat("sr-SR").format(date)
  }

  const rewriteUrl = (url) =>{
    const baseUrl = "https://photos.izarada.com/file/internet-zarada/internet-zarada/izarada-media/"

    return baseUrl + url
  }

  // console.log(data)

  const headAtributes = {"seoTitle":seoTitle, "seoDescription":searchDescription, "url":url,"image":rewriteUrl(homepage.featuredImage.file)}
  

  // console.log(headAtributes)

  return (
    <Layout>
      <HeadElements headAtributes={headAtributes} />
      <div className="w-full h-screen md:h-1/2 lg:h-1/2 xl:h-screen relative bg-black">
        <img
          src={rewriteUrl( homepage.featuredImage.file)}
          alt={homepage.featuredImage.title}
          className="opacity-80 object-cover h-screen w-full absolute md:h-full"
        ></img>
        <div className="p-8 relative lg:left-0 lg:top-1/3 lg:w-2/5">
          <div className="bg-gray-100 relative p-8">
            <h2 className="text-primary text-4xl font-bold">
              {homepage.featuredArticle.title}
            </h2>
            <p className=" my-4  italic  text-lg text-gray-600">
              {homepage.featuredArticle.subTitle}
            </p>
            <Link
              className=" text-lg font-bold text-red-600"
              to={homepage.featuredArticle.url}
            >
              Procitaj vise
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full p-10 text-xl font-bold text-center">
        <h2 className="text-primary mx-auto">Poslednji clanci</h2>
      </div>
      <div className="lg:flex xl:px-52">
        {blogpages.map(element => {
          return (
            <div key={element.id} className="container p-10 mb-2">
              <div className="text-center">
                <div className="h-60 xl:h-52">
                  <img
                    src={rewriteUrl( element.image.file)}
                    className="object-cover h-full w-full"
                    alt={element.image.title}
                  ></img>
                </div>
                <span className="font-bold text-sm uppercase block p-2 text-gray-500 mt-3">
                  Objavljeno: {formatDate(element.date)}
                </span>

                <h3 className="text-2xl text-gray-800 uppercase font-bold">
                  {element.title}
                </h3>
                <p className="italic p-2 text-base text-gray-600">
                  {element.subTitle}
                </p>
                <Link className="text-primary mt-4" to={element.url}>
                  Procitaj vise
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="relative bg-red-800 text-gray-800 text-center">
        <img
          src={diaryImage}
          className=" opacity-80 object-cover h-full w-full absolute top-0 left-0"
          alt="izarada dnevnik"
        ></img>
        <div className="opacity-100 relative  p-10 md:px-24 md:py-14 h-full">
          <h2 className="text-2xl relative font-bold text-gray-800">
            <span className="p-1.5 rounded-lg bg-green-500">
              {homepage.diarySectionTitle}
            </span>
          </h2>
          <p className="p-2 lg:px-32 xl:px-80 xl:py-10 relative italic mb-3 mt-2 font-bold">
          {homepage.diarySectionText}
          </p>
          <button
            className="rounded relative bg-green-500 hover:bg-green-700
              py-2 px-4 text-white font-bold"
            type="button"
          >
            <a href={homepage.diary.url} className="text-white no-underline">Procitaj dnevnik</a>
          </button>
        </div>
      </div>
      <div className="px-11 md:px-16 py-10 align-center">
        <div className=" md:px-8 flex flex-col lg:space-x-4 lg:flex-row sm:container mx-auto relative  text-gray-800 text-center">
          <div className=" p-10 flex-1 bg-primary">
            <h2 className="font-bold text-white text-2xl">Najčitaniji postovi</h2>
            <p className=" overflow-hidden text-lg text-gray-600 italic mt-3">
              Najčitaniji članci sa na ovom blogu ako je vjerovati Google analitici, 
              drugim riječima najkorisnija "lupetanja" autora.  
            </p>
          </div>

          <div className="mx-0 mt-4 lg:mt-0 flex-2 grid gap-4 grid-cols-1 md:grid-cols-2">
            {homepage.selectedArticles.map(element => {
              return (
                <div className="border-gray-300 border-solid border rounded text-left p-6">
                  <span className="block text-sm">
                    OBJAVLJENO: {formatDate(element.article.date)}
                  </span>
                  <h4 className="mt-2">{element.article.title}</h4>
                  <p className="py-2 text-sm">{element.article.subTitle}</p>
                  <Link to={element.article.url}>Saznaj vise</Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homePage {
    swapi {
      homepage(slug: "home") {
        seoTitle
        searchDescription
        url
        featuredArticle {
          id
          title
          url
          intro
          subTitle
        }
        featuredImage {
          file
          title
        }
        selectedArticles {
          article {
            title
            url
            intro
            subTitle
            date
            id
          }
        }
        diarySectionText
        diarySectionTitle
        diary {
          url
          id
        }
      }
      blogpages(limit: 3, order: "-date") {
        title
        url
        id
        intro
        subTitle
        date
        image {
          file
        }
      }
    }
  }
`

export default Home
